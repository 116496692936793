/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


body {
  overscroll-behavior: none;
}

main {
  margin-top: 80px;
  padding: 1rem 1rem;
  box-sizing: border-box;
  padding-bottom: 0 !important;
  background-color: var(--background-color);
  margin-bottom: 0 !important;
  overscroll-behavior: none;
  width: 100vw;

}

.admin-con {
  margin-top: 2.5%;
  padding: 2.5%;
  box-sizing: border-box;
  background-color: whitesmoke;
}

h1 {
  font-size: 1.9rem;
  font-weight: 600;
}

h2 {
  font-size: 1.3rem;
  font-weight: 400;
  margin: 0;
}

.list-title-dropdown {
  font-size: 1.25rem;
  margin: 0;
}

header {
  background-color: var(--primaryColor);
  justify-content: space-between;
  position: fixed;
  z-index: 1000;
  box-shadow: inset -20px 0px 20px 10px var(--shadowNavColor);
}

header img {
  height: 90px;
}

header a {
  font-weight: 300;
}

.navbar {
  width: 100vw;
  justify-content: center;
  padding-left: 2rem;
  height: 90px;
  align-items: flex-end;
}

.navbar ul {
  padding: 0.2rem 0.9rem
}

.navbar li {
  margin-right: 0.25rem;
}

.navbar-brand {
  text-align: left;
}

.socialNavbarLinks {
  justify-content: flex-end !important;
  padding-right: 0;
}

.socialNavbarLinks-top {
  justify-content: flex-end !important;
  padding-right: 0;

}

.socialNavbarLinks-top li {
  padding: 0.1rem 0 !important;
}

.navbar {
  flex-direction: row;
}

.nav {
  display: flex;
  flex-wrap: nowrap;
  /* flex-wrap: wrap; */
  padding-left: 0;
  margin-bottom: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  align-content: flex-end;
  justify-content: flex-end;
  flex-direction: row;
  align-items: unset;
  width: fit-content;
  /* align-items: center; */
}

#basic-nav-dropdown {
  display: -webkit-inline-box;
  align-items: center;
  padding: 4px 8px;
  color: var(--navFontColor) !important;
  height: 32px;
  padding-left: 0px;
  padding-right: 0.5rem;
  font-weight: 700;
  width: 100%;
}

.ulnavbar li {
  margin: 0 1rem;
}

.tabNav-nav {
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  z-index: 999;

  flex-wrap: wrap;
  width: -webkit-fill-available !important;
  background-color: var(--background-color);
}

.nav-tabs {
  border-bottom: var(--secondaryColor) 3px solid;
}


.nav-tabs .nav-link {
  color: black !important;
  background-color: var(--background-color) !important;
  border: none !important;


  /* border: 1px solid var(--utilsColor); */
}

.nav-tabs .nav-link.active {
  color: black !important;
  border-top: var(--secondaryColor) 3px solid !important;
  border-bottom: var(--secondaryColor) 1px solid !important;

}

.nav-tabs .nav-link:hover {
  background-color: var(--secondaryColor) !important;
  border-bottom: var(--secondaryColor) 1px solid !important;
  box-shadow: none !important;
  filter: none !important;

}

.nav-tabs nav-link:focus,
.nav-link:hover {
  color: black !important;
  filter: none !important;

}

.dropdown-item.active,
.dropdown-item:active {
  background-color: none !important;
}

.dropdown-item:focus {
  background-color: none !important;
}

.dropdown-item:hover {
  background-color: none !important;
}


#back-btn {
  background-color: transparent !important;
  border: var(--secondaryColor) 1px solid !important;
  color: var(--secondaryColor)
}

#back-btn:hover {
  background-color: transparent !important;
  border: var(--secondaryColor) 1px solid !important;
  color: var(--secondaryColor);
  filter: none !important;
}

.related-data {
  padding: 2.5%;
  background-color: var(--secondaryColor);
}

nav-link:focus,
.nav-link-nav:hover {
  color: var(--background-color) !important;
}

.badge {
  padding: 0.25em;
  margin-left: -6px;
}

.dropdown-menu {
  background-color: var(--secondaryColor);
  text-align: center;
}

.dropdown-item {
  width: 100%;
  text-align: left;
  padding: 0.3rem !important;

}

#basic-nav-dropdown {
  padding: 8px 8px 4px 15px !important;
  text-align: right;
  justify-content: flex-end;
  min-width: 120px;
}

.dropdown-item.active,
.dropdown-item:active {
  padding: 0.5rem 0.5rem;
}

.nav-item.dropdown {
  height: 32px !important;
  text-align: center !important;
}

i {
  color: var(--IconsColor)
}

#add-btn {
  margin-left: 80%;
  border: 1px solid !important;
  background-color: transparent !important;
  color: black !important;
}

#add-btn svg {
  font-size: 20px;
}

#add-btn:hover {
  transform: translateY(-1px);
  background-color: var(--secondaryColor) !important;

}


.product {
  border-radius: 10%;
  text-align: center;
}



.col-12 {
  padding: 0px;
}

.card-body .product-card-body {
  height: 12rem;
  margin: 0px;
}

.screenRow {
  margin: 1rem auto;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus {
  background-color: var(--secondaryColor) !important;
  color: var(--btnFontColor) !important;
  border: none !important;
  outline: 0;
}

.btn-primary:hover {
  box-shadow: 1px 1px 2px #000000;
}

.eyeButton {
  text-shadow: 2px 2px 4px #000000;
  border-left: none;
}

.eyeButton:focus {
  box-shadow: none !important;
}

.product-button:hover {
  background-color: var(--secondaryColor) !important;
}

.envios-img {
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.mobile {
  display: contents;
}

.mobile-menu {
  flex-direction: row;
  flex-wrap: inherit;
  width: fit-content;
}

.navbar-brand {
  justify-content: flex-start;
  margin: -0.7rem;
}

li {
  margin: 0;
  padding: 4px 0px;
  width: unset;
}

.rightlinks {
  width: 20%;
  justify-content: flex-end;
}

main {
  margin-bottom: 150px;
}

/* .form-control {
  background-color: var(--navFontColor) !important;
} */
.footer-bs {
  width: 100%;
  bottom: 40px;
  left: 0;
  padding: 2rem 1rem;
  background-color: var(--primaryColor);
  display: flex;
  flex-direction: row;
  justify-content: center;
  vertical-align: middle;
  width: 100vw;
  box-shadow: inset 0px 0px 32px -6px var(--shadowNavColor);
}

.footerbrand {
  background-color: var(--primaryColor);
  border-right: var(--secondaryColor) 1px solid;
}

footer .col-12 {
  background-color: var(--primaryColor);
}

footer ul {
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 0;
}

footer li {
  list-style-type: none;
  line-height: normal;
  text-align: justify;
  margin: 0;
  font-size: 0.9rem;
}

footer li:hover {
  text-shadow: 2px 2px 4px #000000;
  color: var(--background-color) !important;
}

footer p {
  text-align: left;
  color: var(--navFontColor);
  font-size: medium;
  font-weight: 600;
  font-size: 1rem;
}

footer h5 {
  text-align: left;
  color: var(--navFontColor);
  font-weight: 800;
  margin-bottom: 0;
}

footer img {
  width: 5rem;
  margin: 1rem 0;
}

footer .col-12 {
  padding: 0.1rem 0.2rem;
}

.social-links {
  display: flex;
  flex-direction: row !important;
  margin: 0;
  justify-content: center;
}

.social-links li {
  width: fit-content;

  padding: 0 1rem;
}

footer .nav-link {
  display: -webkit-inline-box;
  align-items: center;
  color: var(--navFontColor) !important;
  height: auto !important;
  font-weight: 700;
}

.footer-sub {
  font-weight: 800;
  padding-top: 24px;

}

.footerbrand-sub {
  font-weight: 800 !important;
}

.footerbrand-info {
  font-size: .9rem;
}

footer .row {
  justify-content: center;
  align-content: center;
  width: 100%;
  margin: 0;
}

.navbar-expand-lg .navbar-nav {
  margin: 0 1rem;
}

.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 42.4px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.eyeButton {
  background: white;
  border-bottom: 2px solid var(--secondaryColor);
  padding: 0.5px 1rem;
  height: 32px;
  outline: 0 !important;

}

.title-dropdown .dropdown-toggle-split {
  font-size: 1.25rem;
  cursor: pointer !important;
  padding: 4px 12px;
}

.title-dropdown .btn-primary {
  padding: 4px 12px;
}

.list-title {
  font-size: 1rem;
}

.title-dropdown .dropdown-toggle-split:hover {
  font-size: 1.25rem;
}

.list-title-dropdown {
  font-size: 1.25rem;
  margin: 0;
}

.title-btns-row {
  display: flex;
  align-Content: center;
  justify-Content: space-between;
  align-Items: center;
  height: 40px;
}

.dropdown-menu {
  width: -webkit-fill-available;
}

.table-container {
  margin: 0.75rem;
  background-color: var(--background-color);
}

.title-dropdown>.btn-primary,
.title-dropdown>.btn-primary:hover,
.title-dropdown>.btn-primary:active,
.title-dropdown>.btn-primary:visited,
.title-dropdown>.btn-primary:focus,
.title-dropdown>.btn-primary:active {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  cursor: default;
}

.title-dropdown>.btn-primary:active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
}

/* typeahead */
.typeahedSearchIcon {
  flex: 0 0 30px;
  border: none;
  border-radius: 0 0.25rem 0.25rem 0;
  background: #e6e6e6;
  border-bottom: var(--secondaryColor) 2px solid !important;
  cursor: pointer;
}

.rbt-menu {
  position: absolute !important;
  top: calc(100% + 1px) !important;
  left: 0 !important;
  z-index: 999 !important;
}

.rbt-menu.dropdown-menu {
  width: 100% !important;
  border-radius: 0 0 0.25rem 0.25rem !important;
  border-top: none !important;
}

.form-group.required .form-label:before {
  content: "*";
  color: red;
}


.align-items-center {
  justify-content: center;
}

.centered {
  justify-content: center;
  align-items: center;
}

.anularCero {
  color: var(--background-color);
}

.calculatorButton {
  background-color: var(--primaryColor);
  width: 60%;
  justify-content: center;
  align-items: center;
}

.calculatorButton:hover {
  background-color: var(--shadowNavColor);
}


.sidebar-dropdown {
  position: absolute;
  height: max-content;
  right: 2%;
  width: 22%;
  /* Adjust the sidebar width here */
  background-color: var(--navFontColor);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  padding: 10px;
  overflow-y: auto;
}

.filter-opened {
  margin-right: 22.5%;
  transition: margin-right 0.5s ease-out-in;
}

.btn-dark-open,
.btn-dark-open:active,
.btn-dark-open:visited,
.btn-dark-open:focus {
  background-color: var(--primaryColor) !important;
  color: whitesmoke !important;
  ;
}

th {
  vertical-align: middle;
}

.last-col {
  width: 1rem !important
}

/* react-select*/
.css-roh7p6-control {
  min-height: 30px !important;
}

.css-1b1dpuq-control {
  min-height: 30px !important;
  border: none !important;
  border-bottom: var(--secondaryColor) 2px solid !important;
}

.css-1jqi0fh-control {
  min-height: 30px !important;
  border: none !important;
  border-bottom: var(--secondaryColor) 2px solid !important;
}

.css-1fdsijx-ValueContainer {
  min-height: 30px !important;
  border: none !important;
}

.css-1uvbebz-control {
  min-height: 30px !important;
}

.css-1dimb5e-singleValue {
  min-height: 30px !important;
}

.react-select-3-input[aria-expanded="true"] {
  min-height: 30px !important;
  border-bottom: var(--secondaryColor) 2px solid !important;
}

.css-wglrxi-control {
  min-height: 30px !important;
}

.css-14icp6i-control {
  min-height: 30px !important;
}


.dropdown-header {
  font: 1rem sans-serif;
  color: #00377a;
  font-weight: bold;
  text-align: center;
}

.col-md-3 {
  border-radius: 3%;
  background-color: rgb(0, 84, 186, 0.4);
}

a {
  color: #00377a;
  text-decoration: none;
}

li {
  width: 100%;
}

.screenRow {
  margin-top: 1rem;
}

ul {
  padding: 1rem;
  margin: 0 auto;
}

.searchCol li a {
  color: #666;
}

.searchCol li span {
  color: #666;
}

.searchDiv {
  color: #666;
}

.search-select input:focus,
select:focus,
select,
input.form-control:focus {
  border: none !important;
  color: #666;
  background-color: var(--background-color);
}

.tag {
  margin-top: 3px;
  margin-bottom: 3px;
  margin-right: 6px;
  border: 0;
  border-radius: 100px;
  padding: 7px 14px;
  padding-right: 33px;
  float: left;
  font-size: 12px;
  line-height: 1.6666667;
  font-weight: 400;
  text-transform: none;
  position: relative;
}

.tag:not([class*=bg-]) {
  background-color: #03A9F4;
  color: #fff;
}

.tag [data-role="remove"] {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 14px;
  line-height: 1;
  margin-top: -5.5px;
  opacity: 0.7;
  filter: alpha(opacity=70);
}

.tag [data-role="remove"]:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}

.tag [data-role="remove"]:after {
  font-family: "Font Awesome 5 Free";
  content: "\f00d";
  font-weight: 900;
  font-size: 11px;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.titles {
  margin: 1rem !important;
  font-family: Inter var, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Noto Color emoji;
  color: var(--titlesColor);
  font-size: 2rem;
}

.col-md-3 {
  border-radius: 3%;
  background-color: transparent !important;
}

.title-col {
  padding: 16px;
}

.col-envios-contact {
  padding: 2rem;
}

.col-envios-contact li {
  font-size: 14px;
  padding: 0;
}

.btn-outline-info {
  color: var(--utilsColor)
}

.btn-light {
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn-light:hover {
  background-color: var(--secondaryColor)!important;
  border-color: transparent !important;
}

.btn-light:active {
  border: transparent;
}

.admin-con {
  margin-top: 2.5%;
  padding: 2.5%;
  box-sizing: border-box;
  background-color: whitesmoke
}

.col-admin {
  text-align: center;
  padding: 0.25rem;
  margin: 1rem auto;
  box-shadow: 0px 0.5px 1px 0px rgb(0 0 0 / 50%);
}

.admin-form {
  margin: 2.5% auto;
}

td>a {
  color: #0d6efd !important;
  text-decoration: underline !important;
}

.dropstart .btn {
  height: min-content;
  width: 20px;
  padding: unset !important;
}

.modal-btns {
  display: flex;
  flex-direction: row;
  -ms-flex-align: center;
  justify-content: center;
  padding: 2.5%;
  background-color: #e6e6e6;
}

.guardar {
  background-color: green !important;
  color: white !important;
  margin-right: 100%;
  margin-left: 2.5% !important;
}

.cancelar {
  margin-left: 75% !important;
  margin-right: 2.5% !important;

}

.guardar:hover {
  background-color: green !important;
  color: white !important;
}

.col-admin svg {
  vertical-align: middle;
  font-size: 2rem;
  margin-left: 50%;
}

.nav-tabs .nav-item {
  font-weight: 300 !important;
}

.table-users {
  margin: 1px auto;
  display: flex;
  justify-content: space-around;
  vertical-align: middle;
  align-items: center;
  text-align: center;
}

.table-users .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

.orders-table {
  justify-content: space-between;
}

.con-data {
  overflow-y: scroll;
  height: 65vh;
}

.con-data:focus {
  border: black 10px;
}

li {
  list-style: none;
}

/* timeline */
.comments-admin {
  margin: 100px auto;
  width: 60%;
  border-left: solid 2px #ccc;
  padding: 0px 20px 0px 20px;
}

.comments-admin p {
  background-color: #fff;
  padding: 10px;
  font-size: 16px;
  font-family: Inter var, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Noto Color emoji;
  border: solid 1px #ccc;
  line-height: 1.7;
  position: relative;
}

.comments-admin p::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  display: block;
  border: 3px solid #ccc;
  border-radius: 50%;
  background-color: #2c3e50;
  top: 10px;
  left: -30px;
}

.comments-admin p::after {
  content: "";
  position: absolute;
  border: solid 8px;
  border-color: transparent #ccc transparent transparent;
  top: 10px;
  left: -17px;
}

.adminProdBtnRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.btn-prod-admin {
  width: fit-content;
  margin: 0.5rem 0.5rem;
}

.btn:disabled {
  color: grey;
  border-color: grey;
}

#goBack {
  background-color: transparent !important;
  border: none;
}

.container-envios {
  text-align: justify;
}

.table-order {
  justify-content: space-around;
  font-size: small;
}

.table-order .btn {
  font-size: small;
}

.table-products {
  justify-content: space-around;
}

.table-products nav-link {
  text-shadow: 2px 2px 4px #000000;
  color: #e6e6e6 !important;
}

.label-info .tag {
  padding: 1px 5px !important;
}

.label-info {
  padding: 0.1rem 0.2rem !important
}

.label-info .btn {
  padding: 0.05rem 0.2rem !important;
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.pagination .page-item {
  width: auto;

}

.pagination .page-link {
  background-color: var(--background-color) !important;
  cursor: pointer;
  border: none;
}

.page-link:hover {
  background-color: var(--secondaryColor) !important;
}
.card-header-info {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  border-bottom-left-radius: 4px;
}

@media screen and (max-width: 576px) {

  .sidebar-dropdown {
    width: 95% !important;
    right: 0;
    left: 1rem;
    z-index: 2;
  }

}

@media screen and (min-width: 992px) {

  .nav-link .d-lg-block {
    padding-left: 2.5px !important;
  }

}

@media screen and (max-width: 991px) {

  /* .tabNav-nav .nav-item {
    flex: 1 0 100%;
    border-bottom: var(--secondaryColor) 3px solid;
  } */
  .title-btns-row {
    height: auto;
  }

  .mobile-menu {
    flex-wrap: wrap;
    display: flex;
    width: 20vw;
    justify-content: flex-start;
    flex-direction: column;
    align-content: center;
  }

  .navbar {
    flex-wrap: nowrap;
    height: 80px;
    width: 100vw;
    align-items: center;
  }

  .navbar-collapse-Admin {
    justify-content: unset !important;
  }

  .ulnavbar li {
    margin: 0 0.1rem;
  }

  .mobile-menu-admin li {
    margin-left: 1rem;
  }

  .navbar-collapse {
    position: fixed;
    top: 80px;
    left: 0;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    height: 100%;
    background-color: var(--secondaryColor);
    /* box-shadow: inset -20px 0px 20px 10px #00377a45; */
  }

  .navbar-collapse.collapsing {
    left: -75%;
    transition: height 0s ease;
  }

  .rightlinks {
    width: 32px;
    justify-content: flex-end;
  }

  .navbar-collapse.show {
    left: 0;
    transition: left 300ms ease-in-out;
  }

  .navbar-toggler.collapsed~.navbar-collapse {
    transition: left 500ms ease-in-out;
  }

  footer p {
    text-align: center;
  }

  .nav-link {
    display: inline-flex;
  }

  .admin-con {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 100px !important;
  }

  .filter-opened {
    margin-right: 32% !important;
    transition: margin-right 0.5s ease-out-in;
  }

  .sidebar-dropdown {
    width: 30% !important;
  }

  .rounded-pill {
    transform: scale(0.8);
    margin-left: -0.5rem;
    display: inline-flex;
  }

  .Searchbox {
    display: inline-flex;
  }

  header img {
    height: 60px;
    margin-left: -2rem;

  }

  .navbar-brand {
    justify-content: flex-start;
    margin: auto;
    margin-left: 2rem;
  }

  .dropdown-menu {
    background-color: var(--secondaryColor);
    text-align: center;
  }

  .dropdown-item {
    width: 100%;
    text-align: left;
    font-size: 0.8rem;
  }

  .input-group {
    padding: 4px 4px !important;
  }


  #basic-nav-dropdown {
    padding: 12px 4px 0px 0px;
    text-align: right;
    justify-content: flex-end;
    min-width: unset;
    font-size: 0.8rem;
  }

  .nav-item.dropdown {
    height: 32px !important;
    text-align: right !important;
  }

  .footer-bs {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  footer h5 {
    text-align: center;
  }

  footer li {
    text-align: center;
  }

  footer ul {
    border-bottom: var(--secondaryColor) 1px solid;
  }

  .footerbrand {
    border-bottom: none;
    border-right: none !important;
    display: flex;
    flex-direction: column;
    order: 2;
  }

  .footerbrand-img {
    order: 1;
  }

  footer img {
    margin: auto;
    display: block;
    float: none;
  }

  .whatsapp-icon {
    margin-top: 10px;
  }

  .whatsapp_float {
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 10px;
    font-size: 22px;
  }

  .productScreenContainer {
    padding: 1rem !important;
  }

  .pagination {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .adminProdBtnRow {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }

}

.arrow-button {
  display: flex;
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 16px;
}

.arrow-button::after {
  content: '\25BC'; /* Down arrow */
  display: inline-block;
  margin-left: 8px;
  transition: transform 0.3s ease;
}

.arrow-button.collapsed::after {
  content: '\25B2'; /* Up arrow */
  transform: rotate(180deg);
}
